import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d3615dfa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper popup-shadow" }
const _hoisted_2 = {
  key: 0,
  src: "/images/icons/footprint.svg",
  alt: "footprint",
  width: "40",
  height: "40",
  class: "h-12 w-auto object-contain mb-4"
}
const _hoisted_3 = { class: "mb-6 text-center" }
const _hoisted_4 = {
  key: 0,
  class: "pb-4 text-2xl"
}
const _hoisted_5 = { class: "font-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: "fixed z-50 inset-0 flex items-center justify-center bg-opacity-70 bg-black backdrop-filter backdrop-blur-sm p-4",
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClickCancel && _ctx.onClickCancel(...args)), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.hideIcon)
        ? (_openBlock(), _createElementBlock("img", _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.message), 1)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["flex gap-4 w-full justify-center items-center", [_ctx.singleColumn ? 'flex-col-reverse' : '']])
      }, [
        (_ctx.onClickCancel)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              text: _ctx.cancelButtonText || 'Cancel',
              bgColor: "transparent",
              onClick: _ctx.onClickCancel,
              class: "w-full"
            }, null, 8, ["text", "onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Button, {
          text: _ctx.confirmButtonText || 'Confirm',
          bgColor: _ctx.isWarning ? 'warn' : 'green',
          onClick: _ctx.onClickConfirm,
          class: _normalizeClass(_ctx.onClickCancel ? 'w-full' : 'justify-self-center')
        }, null, 8, ["text", "bgColor", "onClick", "class"])
      ], 2)
    ])
  ]))
}