
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from 'vue'

import { inviteUserPopupState, windowState } from '@/store'
import { EmitValidInput } from '@/types/emits'
import { backendFetch } from '@/helpers/fetch'
import { CreateUserPayload } from '@/types/server/routes/auth'
import { userRole } from '@/constants'

import Input from '@/components/atoms/Input'
import Button from '@/components/atoms/Button'
import InputDropdown from '@/components/molecules/InputDropdown'

export default defineComponent({
  components: {
    Input,
    Button,
    InputDropdown,
  },
  setup() {
    const selectableRoles = ref<string[]>([])
    const state = reactive({
      email: '',
      role: '',
      errorMessage: '',
      isLoading: false,
    })

    onMounted(() => (windowState.allowScroll = false))
    onUnmounted(() => (windowState.allowScroll = true))

    const areInputsValid = computed(() => {
      return !!(/^.+@yourfootprint.co$/.test(state.email) && state.role)
    })

    const onSelectRole = ({ valid, text }: EmitValidInput) => {
      if (!valid) return
      state.role = text
    }

    const createUser = async () => {
      if (state.isLoading) return
      state.errorMessage = ''
      try {
        state.isLoading = true
        const payload: CreateUserPayload = {
          email: state.email,
          role: state.role as keyof typeof userRole,
        }
        const { status } = await backendFetch('/api/auth/user', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        })
        if (status !== 200) throw Error()
        state.email = ''
        state.role = ''
      } catch (error) {
        console.error(error)
        state.errorMessage = 'An error occurred.'
      } finally {
        state.isLoading = false
      }
    }

    const main = async () => {
      const { data } = await backendFetch('/api/auth/roles')
      if (!data) return
      selectableRoles.value = data
    }

    main()

    return {
      state,
      selectableRoles,
      onSelectRole,
      areInputsValid,
      createUser,
      inviteUserPopupState,
    }
  },
})
