<template>
  <footer class="h-40 text-grey-fp-4 bg-black">
    <div
      class="
        h-full
        container
        mx-auto
        flex flex-col
        justify-center
        sm:flex-row sm:justify-between
        items-center
      "
    >
      <a href="https://www.yourfootprint.co/" target="_blank">
        <img src="/images/footprint.png" alt="Footprint" width="107" />
      </a>
      <a
        href="https://www.yourfootprint.co/business"
        target="_blank"
        class="my-6 sm:my-0"
      >
        yourfootprint.co/business
      </a>
      <a href="mailto:support@yourfootprint.com">support@yourfootprint.co</a>
    </div>
  </footer>
</template>
