import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60cabb49"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["srcset", "type", "media"]
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = {
  key: 0,
  class: "gradient"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("picture", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sources, (s) => {
      return (_openBlock(), _createElementBlock("source", {
        key: s,
        srcset: s.src,
        type: s.type,
        media: s.media
      }, null, 8, _hoisted_1))
    }), 128)),
    _createElementVNode("img", {
      src: `${_ctx.cloudinaryUrl}c_fill,w_1920,h_${
        _ctx.imgHeight || '1080'
      }/${_ctx.cloudinaryImgId}`,
      alt: _ctx.cloudinaryImgId,
      class: "w-full h-full object-cover"
    }, null, 8, _hoisted_2),
    (_ctx.useGradient)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}