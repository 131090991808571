import { routeNames, indexedRoutes } from '@/router'
import { waitForWhoAmIResponseMiddleware } from '@/router/middleware'
import {
  getGroupViewRouteOptions,
  getEditorRouteOptions,
} from '@/router/mixins'
import { RouteRecordRaw } from 'vue-router'

const OverviewPage = () =>
  import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Overview')

const B2CUpdatesContent = () =>
  import(
    /* webpackChunkName: "dashboard" */ '@/views/dashboard/ContentViews/B2CUpdates'
  )
const B2CChallengesContent = () =>
  import(
    /* webpackChunkName: "dashboard" */ '@/views/dashboard/ContentViews/B2CChallenges'
  )
const B2BAdviceContent = () =>
  import(
    /* webpackChunkName: "dashboard" */ '@/views/dashboard/ContentViews/B2BAdvice'
  )

const B2CUpdatesEditor = () =>
  import(
    /* webpackChunkName: "dashboard" */ '@/views/dashboard/ContentEditorViews/B2CUpdates'
  )
const B2CChallengesEditor = () =>
  import(
    /* webpackChunkName: "dashboard" */ '@/views/dashboard/ContentEditorViews/B2CChallenges'
  )
const B2BAdviceEditor = () =>
  import(
    /* webpackChunkName: "dashboard" */ '@/views/dashboard/ContentEditorViews/B2BAdvice'
  )

const CompensationsPage = () =>
  import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Compensations')

const CompaniesPage = () =>
  import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Companies')

const dashboardRoutes: RouteRecordRaw[] = [
  {
    path: '/dashboard/:overview',
    name: routeNames.OVERVIEW,
    component: OverviewPage,
    meta: {
      layout: 'Dashboard',
      redirectToIfNoAuth: {
        name: routeNames.LOGIN,
      },
    },
  },

  {
    path: `/dashboard/${indexedRoutes['b2c-updates']}/:groupId`,
    name: routeNames['b2c-updates'],
    component: B2CUpdatesContent,
    ...getGroupViewRouteOptions('b2c-updates'),
  },
  {
    path: `/dashboard/${indexedRoutes['business-advice']}/:groupId`,
    name: routeNames['business-advice'],
    component: B2BAdviceContent,
    ...getGroupViewRouteOptions('business-advice'),
  },
  {
    path: `/dashboard/${indexedRoutes['b2c-challenges']}/:groupId`,
    name: routeNames['b2c-challenges'],
    component: B2CChallengesContent,
    ...getGroupViewRouteOptions('b2c-challenges'),
  },

  {
    path: `/dashboard/${indexedRoutes['b2c-updates']}/:groupId/editor/:docId?`,
    name: routeNames.EDITOR_B2C_UPDATES,
    component: B2CUpdatesEditor,
    ...getEditorRouteOptions('b2c-updates'),
  },
  {
    path: `/dashboard/${indexedRoutes['business-advice']}/:groupId/editor/:docId?`,
    name: routeNames.EDITOR_BUSINESS_ADVICE,
    component: B2BAdviceEditor,
    ...getEditorRouteOptions('business-advice'),
  },
  {
    path: `/dashboard/${indexedRoutes['b2c-challenges']}/:groupId/editor/:docId?`,
    name: routeNames.EDITOR_B2C_CHALLENGES,
    component: B2CChallengesEditor,
    ...getEditorRouteOptions('b2c-challenges'),
  },

  {
    path: '/dashboard/compensations',
    name: routeNames.COMPENSATIONS,
    component: CompensationsPage,
    meta: {
      layout: 'Dashboard',
      navigationText: [
        {
          text: 'Overview',
        },
      ],
    },
    beforeEnter: waitForWhoAmIResponseMiddleware,
  },
  {
    path: '/dashboard/companies',
    name: routeNames.COMPANIES,
    component: CompaniesPage,
    meta: {
      layout: 'Dashboard',
      navigationText: [
        {
          text: 'Overview',
        },
      ],
    },
    beforeEnter: waitForWhoAmIResponseMiddleware,
  },
]

export default dashboardRoutes
