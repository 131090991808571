
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  h,
  PropType,
  resolveComponent,
  VNode,
} from 'vue'
import { RouteLocationRaw } from 'vue-router'

import PlusSign from '@/components/atoms/PlusSign'
const Spinner = defineAsyncComponent(() => import('@/components/atoms/Spinner'))

export default defineComponent({
  name: 'Button',
  components: {
    PlusSign,
    Spinner,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    showPlusSign: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Object as PropType<{
        src: string
        alt: string
        class?: string
        position?: 'left' | 'right'
      }>,
      default: () => ({
        src: '',
        alt: '',
        class: '',
        position: '',
      }),
    },
    bgColor: {
      type: String as () =>
        | 'green'
        | 'grey'
        | 'white'
        | 'lighter-grey'
        | 'black'
        | 'transparent'
        | 'warn'
        | 'warn-outline'
        | 'custom',
      default: 'custom',
    },
    anchorTo: {
      type: Object as () => RouteLocationRaw,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const bgColor = computed(() => {
      const darkBorder = 'border border-grey-fp-3 '
      const bgColors = {
        green: {
          base: 'bg-green-fp-2',
          hover: 'hover:bg-green-fp-3',
        },
        grey: {
          base: darkBorder + 'bg-grey-fp-1',
          hover: 'hover:bg-grey-fp-3',
        },
        white: {
          base: 'bg-white text-black',
          hover: 'hover:bg-grey-fp-4',
        },
        'lighter-grey': {
          base: 'bg-grey-fp-2',
          hover: 'hover:bg-grey-fp-3',
        },
        black: {
          base: darkBorder + 'bg-black',
          hover: 'hover:bg-grey-fp-3',
        },
        transparent: {
          base: 'bg-transparent border border-grey-fp-4',
          hover: 'hover:bg-white hover:bg-opacity-20',
        },
        warn: {
          base: 'bg-warn-strong text-white',
          hover: 'hover:bg-warn-light hover:text-white',
        },
        'warn-outline': {
          base: 'bg-transparent border border-warn-strong text-warn-strong',
          hover: 'hover:bg-warn-strong hover:text-white',
        },
        custom: {
          base: '',
          hover: '',
        },
      }
      return props.isLoading
        ? bgColors[props.bgColor].base
        : `${bgColors[props.bgColor].base} ${bgColors[props.bgColor].hover}`
    })

    const getChildren = computed(() => {
      const children: VNode[] = []
      if (props.isLoading) {
        children.push(
          h(resolveComponent('Spinner'), {
            class: 'absolute inset-0',
            type: 'fp-spinner',
          })
        )
      } else {
        if (props.text)
          children.push(
            h(
              'p',
              {
                class: [
                  props.showPlusSign ? 'mr-3' : '',
                  'font-bold text-base',
                ],
              },
              props.text
            )
          )

        if (props.icon.src) {
          const imgClassList: string[] = []

          if (props.icon.class) imgClassList.push(props.icon.class)
          if (props.text)
            imgClassList.push(props.icon.position === 'left' ? 'mr-3' : 'ml-3')

          const img = h('img', {
            src: props.icon.src,
            alt: props.icon.alt,
            class: imgClassList,
          })

          if (props.icon.position === 'left') {
            children.unshift(img)
          } else {
            children.push(img)
          }
        }

        if (props.showPlusSign) children.push(h(PlusSign))
      }

      return children
    })

    const getBaseElement = () => {
      if (props.anchorTo) return resolveComponent('router-link') as any
      return 'button'
    }

    return () =>
      h(
        getBaseElement(),
        {
          class: [
            'button relative',
            props.text ? 'min-button-width' : '',
            bgColor.value,
            props.isDisabled ? 'opacity-50 pointer-events-none' : '',
          ],
          ...(props.anchorTo && { to: props.anchorTo }),
        },
        {
          // The default slot
          default() {
            return getChildren.value
          },
        }
      )
  },
})
