import { PropType } from 'vue'
import InputMixin from './InputMixin'

const { downArrowDir, ...sharedInputProps } = InputMixin.props

export default {
  emits: [...InputMixin.emits, 'input-valid'] as const,
  props: {
    ...sharedInputProps,
    initialValue: {
      type: String,
      default: '',
    },
    /**
     * The elements the user can search for and select.
     */
    searchableElements: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    showInvalidSelectionTextWhenApplicable: {
      type: Boolean,
      default: true,
    },
    /**
     * Upon click, should the dropdown open?
     */
    showOnClick: {
      type: Boolean,
      default: false,
    },
    /**
     * Include the powered by google logo?
     * Required when using some of their services (like location dropdown)
     */
    poweredByGoogle: {
      type: Boolean,
      default: false,
    },
    /**
     * Should the dropdown send out 'input-valid' emit on load?
     * This should be true if we need to calculate things right away.
     */
    emitInitially: {
      type: Boolean,
      default: true,
    },
  },
}
