
import { defineComponent, onMounted, onUnmounted, toRefs } from 'vue'

import { confirmPopupState, windowState } from '@/store'

import Button from '@/components/atoms/Button'

export default defineComponent({
  components: {
    Button,
  },
  setup() {
    onMounted(() => (windowState.allowScroll = false))
    onUnmounted(() => (windowState.allowScroll = true))
    return {
      ...toRefs(confirmPopupState),
    }
  },
})
