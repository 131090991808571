
import { defineComponent, shallowRef, watch } from 'vue'
import { useRoute } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import DashboardLayout from '@/layouts/Dashboard'
import BareLayout from '@/layouts/Bare'

const layouts = {
  Default: DefaultLayout,
  Dashboard: DashboardLayout,
  Bare: BareLayout,
}

export default defineComponent({
  name: 'LayoutConstructor',
  setup() {
    const route = useRoute()
    const layout = shallowRef(DefaultLayout)

    watch(
      () => route.meta,
      async ({ layout: wantedLayout }) => {
        layout.value = wantedLayout ? layouts[wantedLayout] : layouts['Default']
      },
      {
        immediate: true,
      }
    )

    return {
      layout,
    }
  },
})
