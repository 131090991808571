
import { defineComponent } from 'vue'

import DynamicCloudinaryImg from '@/components/molecules/DynamicCloudinaryImg'

export default defineComponent({
  components: {
    DynamicCloudinaryImg,
  },
})
