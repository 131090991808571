import { user } from '@/store'
import { WhoAmIResponse } from '@/types/server/routes/auth'
import { backendFetch } from '.'

/**
 * Functions to run after user data is fetched.
 */
export const afterFetchUserData: (() => void)[] = []

export const fetchUserData = async () => {
  const { data } = await backendFetch<WhoAmIResponse>(
    '/api/auth/whoami',
    {},
    { allowRetry: false }
  )

  if (data) {
    Object.assign(user, data)
    user.hasLoaded = true
    let func: typeof afterFetchUserData[number] | undefined
    do {
      func = afterFetchUserData.pop()
      func?.()
    } while (func)
  }

  user.hasLoaded = true
}
