export * from './types'
export * from './routes'

import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
} from 'vue-router'
import routes from './routes'
import { overviews } from '@/store'
import { routeNames } from './types'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    window.scrollTo({
      top: 0,
      left: 0,
    })
  },
})

router.beforeEach(async (to, from, next) => {
  // The beforeEnter function for a dynamic route
  // is not called multiple times when navigating from and to the same dynamic route.
  // Therefore, we put it here.
  if (to.name !== routeNames.OVERVIEW) return next()
  const { overview } = to.params as { overview: keyof typeof overviews }
  if (!overviews[overview].OverviewItems.length)
    await overviews[overview].FetchOverviewItems()
  return next()
})

export const navigationHistory: RouteLocationNormalized[] = []

router.afterEach((route) => {
  navigationHistory.push(route)
  if (navigationHistory.length > 20) {
    navigationHistory.shift()
  }
})

export default router
