export * from './notifications'

import { fallbackContentKeys, userRole } from '@/constants'
import { backendFetch } from '@/helpers/fetch'
import { routeNames, indexedRoutes } from '@/router'
import { NavigationText } from '@/types/custom'
import { OverviewResponse } from '@/types/server/routes/data'
import { RouteLocation } from 'vue-router'
import { overviews, user } from '..'

export const getRouteData = async (apiRoute: string, routeName: string) => {
  const { data } = await backendFetch<OverviewResponse[]>(apiRoute, {})
  if (!data) return []
  return data.map((d) => ({
    ...d,
    id: d.id ?? fallbackContentKeys['b2c-updates'].noProjectSelected,
    anchorTo: {
      name: routeName,
      params: {
        groupId: d.id ?? fallbackContentKeys['b2c-updates'].noProjectSelected,
      },
    },
  }))
}

export const getOverviewGroupItem = (
  overviewKey: keyof typeof overviews,
  groupId: string
) => overviews[overviewKey].OverviewItems.find((item) => item.id === groupId)

/**
 * The base at which navigation text appears.
 * E.g. /dashboard/b2c-updates/indonesia-rainforest-protection
 * @param overviewKey
 * @param route
 * @returns
 */
export const getBaseNavigationText = (
  overviewKey: keyof typeof overviews,
  route: RouteLocation
) => {
  const text = getOverviewGroupItem(
    overviewKey,
    route.params.groupId as string
  )?.title

  const data: NavigationText[] = [
    {
      text: 'Overview',
      route: {
        name: routeNames.OVERVIEW,
        params: { overview: indexedRoutes[overviewKey] },
      },
    },
    {
      text: text || 'Unknown',
    },
  ]

  return data
}

/**
 * Navigation texts for the editor page
 * @param overviewKey
 * @param contentRoute
 * @param route
 * @returns
 */
export const getEditorNavigationText = (
  overviewKey: keyof typeof overviews,
  contentRoute: keyof typeof routeNames,
  route: RouteLocation
) => {
  const previousNavigationText = getBaseNavigationText(overviewKey, route)
  previousNavigationText[1].route = {
    name: routeNames[contentRoute],
    params: { groupId: route.params.groupId },
  }
  previousNavigationText.push({
    text: 'Content Editing',
  })
  return previousNavigationText
}

export const isUserRole = (role: keyof typeof userRole) => {
  return userRole[user.role] >= userRole[role]
}
