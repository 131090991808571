
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    size: {
      type: String as () => 'normal' | 'large',
      default: 'normal',
    },
  },
})
