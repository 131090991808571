
import { defineComponent, PropType } from 'vue'

import { NavItem } from '.'

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<NavItem>,
      required: true,
    },
  },
})
