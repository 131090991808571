export * from './b2c-challenges'

export const csrfCookieName = 'fp_csrf'

export const userRole = {
  viewer: 0,
  editor: 1,
  owner: 2,
}

export const minPasswordLength = 8

export const compensationSolutions = {
  all: 'All',
  business: 'Business',
  shopify: 'Shopify',
} as const

export const fallbackContentKeys = {
  'b2c-updates': {
    noProjectSelected: 'general',
  },
}
