import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full min-h-screen flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_LayoutConstructor = _resolveComponent("LayoutConstructor")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_Popup = _resolveComponent("Popup")!
  const _component_TheInviteUsers = _resolveComponent("TheInviteUsers")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.meta.showHeader)
      ? (_openBlock(), _createBlock(_component_Header, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_LayoutConstructor, { class: "flex-1" }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.$route.meta.layout !== 'Dashboard')
      ? (_openBlock(), _createBlock(_component_Footer, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.confirmPopupState.show)
      ? (_openBlock(), _createBlock(_component_Popup, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.inviteUserPopupState.show)
      ? (_openBlock(), _createBlock(_component_TheInviteUsers, { key: 3 }))
      : _createCommentVNode("", true)
  ]))
}