export const indexedRoutes = {
  'business-advice': 'business-advice',
  'b2c-updates': 'b2c-updates',
  'b2c-challenges': 'b2c-challenges',
}

export const routeNames = {
  ...indexedRoutes,
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
  OVERVIEW: 'OVERVIEW',
  EDITOR_BUSINESS_ADVICE: 'EDITOR_BUSINESS_ADVICE',
  EDITOR_B2C_UPDATES: 'EDITOR_B2C_UPDATES',
  EDITOR_B2C_CHALLENGES: 'EDITOR_B2C_CHALLENGES',
  COMPENSATIONS: 'COMPENSATIONS',
  COMPANIES: 'COMPANIES',
  ERROR: 'ERROR',
}
