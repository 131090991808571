export * from './misc'
export * from './posts'

import { indexedRoutes } from '@/router'
import { backendFetch } from './fetch'
import router from '@/router'
import { RouteLocationRaw } from 'vue-router'
import { confirmPopupState } from '@/store'
import { B2BAdvice, B2CUpdate } from '@/types/server/models'
import { OnToggleLiveReturn } from '@/components/molecules/EditableCard'

const deletionsInProgress: Record<string, boolean> = {}

export const deleteDocument = async (
  route: keyof typeof indexedRoutes,
  _id: string,
  redirect: RouteLocationRaw
) => {
  confirmPopupState.onClickCancel = async () => {
    confirmPopupState.show = false
  }

  confirmPopupState.onClickConfirm = async () => {
    if (!_id || deletionsInProgress[_id]) return
    deletionsInProgress[_id] = true

    try {
      await backendFetch(`/api/data/${route}/${_id}`, { method: 'DELETE' })
      router.push(redirect)
    } catch (error) {
      console.error(error)
    } finally {
      delete deletionsInProgress[_id]
      confirmPopupState.show = false
    }
  }

  confirmPopupState.message = 'Are you sure you want to delete this?'
  confirmPopupState.show = true
}

type SetLiveParams = {
  item: B2BAdvice | B2CUpdate
  overviewKey: string
  isLive: boolean
}

export const setLive = async ({
  item,
  overviewKey,
  isLive,
}: SetLiveParams): Promise<OnToggleLiveReturn> => {
  item.isLive = isLive
  try {
    const { status, errors } = await backendFetch(
      `/api/data/${overviewKey}/${item._id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ isLive }),
      }
    )
    item.isLive = isLive && status < 300
    return {
      errors,
    }
  } catch (error) {
    console.error(error)
    item.isLive = false
    return {
      errors: ['An error occurred'],
    }
  }
}
