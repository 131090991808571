
import { defineComponent, computed, watch, reactive, toRefs, toRef } from 'vue'

import InputDropdownMixin from '@/mixins/InputDropdownMixin'
import { getEscapedTextForRegExp } from '@/helpers'
import { EmitValidInput } from '@/types/emits'

import Input from '@/components/atoms/Input'

type State = {
  inputValue: string
  isValidElement: boolean
  isInputFocused: boolean
  filteredElements: string[]
  numSearchableElementsToDisplay: number
  errorText: string
}

export default defineComponent({
  inheritAttrs: false,
  components: {
    Input,
  },
  emits: [...InputDropdownMixin.emits],
  props: {
    ...InputDropdownMixin.props,
  },
  setup(props, { emit }) {
    const maxSearchableElementsToDisplayInitially = 40

    const getMinToDisplay = () =>
      props.searchableElements.length < maxSearchableElementsToDisplayInitially
        ? props.searchableElements.length
        : maxSearchableElementsToDisplayInitially

    let minSearchableElementsToDisplay = getMinToDisplay()

    const searchableElements = toRef(props, 'searchableElements')

    const state = reactive<State>({
      inputValue: props.initialValue,
      isValidElement: false,
      isInputFocused: false,
      filteredElements: [],
      numSearchableElementsToDisplay: minSearchableElementsToDisplay,
      errorText: '',
    })

    /**
     * Sometimes we shouldn't emit 'input-valid' right away.
     * This is set to true after initial `setup()` but not always during.
     */
    let allowInputValidEmit = !!props.emitInitially

    watch(
      () => props.initialValue,
      () => {
        state.inputValue = props.initialValue
      }
    )

    // When the parent changes the searchable elements,
    // reset everything
    watch(
      () => props.searchableElements,
      () => {
        searchableElements.value = props.searchableElements
        state.filteredElements = []
        minSearchableElementsToDisplay = getMinToDisplay()
        state.numSearchableElementsToDisplay = minSearchableElementsToDisplay
      }
    )

    // When our searchable elements change
    watch(searchableElements, () => {
      // If we have an input, check that it exists
      if (state.inputValue) onActions.onInputChange(state.inputValue)
    })

    let previousMatch = ''

    const filterElements = () => {
      const eligibleElements = []
      const inputValueLowerCase = state.inputValue.toLowerCase()

      let isMatch = false
      const numDisplayedElementsCondition = 2
      const numMatchingCharactersCondition = 6

      for (const element of searchableElements.value) {
        const elementLowerCase = element.toLowerCase()

        isMatch =
          // Check for an exact match.
          elementLowerCase === inputValueLowerCase ||
          /**
           * Then check for another kind of match.
           * E.g. if the dropdown displays "Germany DE" and the user inputs
           * "German" and there's only `numDisplayedElementsCondition` elements in the dropdown,
           * we can safely select "Germany DE" as an option.
           */
          (element !== previousMatch &&
            state.numSearchableElementsToDisplay <=
              numDisplayedElementsCondition &&
            elementLowerCase.startsWith(inputValueLowerCase) &&
            element.length - inputValueLowerCase.length <
              numMatchingCharactersCondition)

        if (isMatch) {
          previousMatch = element
          state.inputValue = element
          state.isValidElement = true
          emitValidInput(true)
          break
        }

        // Only show the dropdown values that are close to user input
        if (
          new RegExp(getEscapedTextForRegExp(state.inputValue), 'i').test(
            element
          )
        ) {
          eligibleElements.push(element)
        }
      }

      if (!isMatch) emitValidInput(false)
      state.errorText = ''

      // When user clicks and showOnClick === true, show default options
      const showDefaults =
        props.showOnClick &&
        state.isInputFocused &&
        !isMatch &&
        (!eligibleElements.length || inputValueLowerCase.length < 2)

      if (showDefaults) {
        state.filteredElements = searchableElements.value
        state.numSearchableElementsToDisplay = minSearchableElementsToDisplay
        return
      }

      if (eligibleElements.length < minSearchableElementsToDisplay)
        state.numSearchableElementsToDisplay = eligibleElements.length

      state.filteredElements = eligibleElements
    }

    const emitValidInput = (valid: boolean) => {
      if (!allowInputValidEmit) return
      if (searchableElements.value.length)
        emit('input-valid', { valid, text: state.inputValue } as EmitValidInput)
    }

    const onActions = {
      onInputChange: (value: string, click = false) => {
        if (click) (document.activeElement as HTMLElement)?.blur()
        if (!value) emitValidInput(false)
        state.isValidElement = false
        state.inputValue = value
        emit('input-value', value)
        filterElements()
      },
      onInputEnter: () =>
        state.filteredElements[0] &&
        onActions.onInputChange(state.filteredElements[0]),
      onMouse: (value: boolean) => {
        state.isInputFocused = value
        if (value && props.showOnClick) {
          state.filteredElements = searchableElements.value
          state.numSearchableElementsToDisplay = minSearchableElementsToDisplay
        }

        if (!value && !state.isValidElement && state.inputValue) {
          if (props.showInvalidSelectionTextWhenApplicable) {
            state.errorText = 'Invalid selection'
          }
        } else {
          state.errorText = ''
        }
      },
      onScrollElements: (e: Event & { target: HTMLElement }) => {
        const wantedNum = Math.round(e.target.scrollTop / 20)
        if (wantedNum < minSearchableElementsToDisplay) return
        if (wantedNum > state.filteredElements.length) {
          state.numSearchableElementsToDisplay = state.filteredElements.length
          return
        }
        state.numSearchableElementsToDisplay = wantedNum
      },
    }

    const showDropdown = computed(
      () => state.isInputFocused && props.showOnClick // || (!state.isValidElement && state.inputValue)
    )

    const downArrowDir = computed(() => {
      if (props.showDeleteButton) return
      return showDropdown.value && searchableElements.value.length
        ? 'up'
        : 'down'
    })

    // If a :initialValue was set on this component, act like it's changing input
    if (state.inputValue) onActions.onInputChange(state.inputValue)

    allowInputValidEmit = true

    return {
      ...toRefs(state),
      searchableElements,
      showDropdown,
      downArrowDir,
      ...onActions,
    }
  },
})
