
import { defineComponent, PropType } from 'vue'
import { useRoute } from 'vue-router'

import { NavigationText } from '@/types/custom'
import { navigationHistory } from '@/router'

export default defineComponent({
  props: {
    routeNavigationTexts: {
      type: Object as PropType<NavigationText[]>,
      required: false,
    },
  },
  setup(props) {
    const route = useRoute()
    const navigationTexts =
      props.routeNavigationTexts || route.meta.navigationText || []

    return {
      navigationTexts,
      navigationHistory,
    }
  },
})
