import { createApp } from 'vue'
import App from './App.vue'
import router, { indexedRoutes, routeNames, navigationHistory } from './router'
import './styles/global.scss'

const app = createApp(App).use(router)

app.config.globalProperties.$routeNames = routeNames
app.config.globalProperties.$indexedRoutes = indexedRoutes
app.config.globalProperties.$isUserRole = isUserRole
app.config.globalProperties.$goBack = () => {
  if (navigationHistory[navigationHistory.length - 2]) {
    router.back()
  }
}

// Register global components
import NavigationText from '@/components/atoms/NavigationText'
import { isUserRole } from './store'
app.component('NavigationText', NavigationText)

app.mount('#app')

// Define custom globalProperties
declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $routeNames: typeof routeNames
    $indexedRoutes: typeof indexedRoutes
    $isUserRole: typeof isUserRole
    /**
     * Checks if the router can navigate back a route,
     * and if so, does so.
     */
    $goBack: () => void
  }
}
