import { OverviewResponse } from '@/types/server/routes/data'
import { RouteLocationRaw } from 'vue-router'

export type ConfirmPopupState = {
  show: boolean
  title: string
  message: string
  isWarning?: boolean
  singleColumn?: boolean
  cancelButtonText?: string
  confirmButtonText?: string
  hideIcon?: boolean
  onClickCancel?: () => Promise<void>
  onClickConfirm?: () => Promise<void>
}

export type OverviewItem = OverviewResponse & { anchorTo: RouteLocationRaw }

export class Overview<ContentType = any> {
  public constructor(
    public navTitle: string,
    public overviewDescription: string,
    public anchorTo: RouteLocationRaw,
    private readonly getOverviewItems: () => Promise<OverviewItem[]>,
    private readonly getGroupedDocuments: (
      id: string
    ) => Promise<ContentType[]>,
    private readonly getSingleDocument: (id: string) => Promise<ContentType>
  ) {}

  private overviewItems: OverviewItem[] = []
  /**
   * The fetched items displayed on the overview page
   */
  get OverviewItems() {
    return this.overviewItems
  }

  private groupedDocuments: Record<string, ContentType[]> = {}
  /**
   * The fetched documents grouped by the group id.
   * E.g. for b2c updates, this id is the projectId (like 'indonesia-rainforest-protection')
   */
  get GroupedDocuments() {
    return this.groupedDocuments
  }

  private singleDocuments: Record<string, ContentType> = {}
  get SingleDocuments() {
    return this.singleDocuments
  }

  private async fetchOverviewItems() {
    this.overviewItems = await this.getOverviewItems()
  }
  /**
   * Assign `items` to the value returned by the async function
   * `getItems`
   */
  get FetchOverviewItems() {
    return this.fetchOverviewItems
  }

  private async fetchGroupedDocuments(id: string) {
    const documents = await this.getGroupedDocuments(id)
    this.groupedDocuments[id] = documents
  }
  get FetchGroupedDocuments() {
    return this.fetchGroupedDocuments
  }

  private async fetchSingleDocument(id: string) {
    const document = await this.getSingleDocument(id)
    this.singleDocuments[id] = document
  }
  get FetchSingleDocument() {
    return this.fetchSingleDocument
  }
}
