
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    cloudinaryImgId: {
      type: String,
      required: true,
    },
    cloudinaryTransformation: {
      type: String,
      default: '',
    },
    imgHeight: {
      type: Number,
      required: false,
    },
    useGradient: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const initialTransformation = props.cloudinaryTransformation
      ? `${props.cloudinaryTransformation},`
      : ''
    const sourceSizes = [
      {
        cloudinaryTransformation: `${initialTransformation}w_2560,h_${
          props.imgHeight || '1440'
        }`,
        mediaQuery: '(min-width:1920px)',
      },
      {
        cloudinaryTransformation: `${initialTransformation}w_1920,h_${
          props.imgHeight || '1080'
        }`,
        mediaQuery: '(min-width:1140px)',
      },
      {
        cloudinaryTransformation: `${initialTransformation}w_1140,h_${
          props.imgHeight || '1080'
        }`,
        mediaQuery: '(min-width:768px)',
      },
      {
        cloudinaryTransformation: `${initialTransformation}w_768,h_${
          props.imgHeight || '768'
        }`,
        mediaQuery: '(min-width:0px)',
      },
    ]

    type Source = {
      src: string
      type: string
      media: string
    }

    const cloudinaryUrl = process.env.VUE_APP_CLOUDINARY_URL
    const sources = sourceSizes.reduce((prev, curr) => {
      const base = `${cloudinaryUrl}c_fill,${curr.cloudinaryTransformation}/${props.cloudinaryImgId}`
      return [
        ...prev,
        {
          src: `${base}.webp`,
          type: 'image/webp',
          media: curr.mediaQuery,
        },
        {
          src: `${base}.jpg`,
          type: 'image/jpeg',
          media: curr.mediaQuery,
        },
      ]
    }, [] as Source[])

    return {
      cloudinaryUrl,
      sources,
    }
  },
})
