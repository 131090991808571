import { backendFetch } from '@/helpers/fetch'
import { indexedRoutes } from '@/router'
import { GetB2CChallengesEditorData } from '@/types/server/routes/data'
import { b2cChallengesEditorRouteData } from '../mini'

export const fetchB2CChallengeEditorData = async () => {
  const { data } = await backendFetch<GetB2CChallengesEditorData>(
    `/api/data/${indexedRoutes['b2c-challenges']}/editor`,
    {},
    { useCache: true }
  )
  b2cChallengesEditorRouteData.value = data
}
