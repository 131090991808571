import { B2BAdvice, B2CChallenge, B2CUpdate } from '@/types/server/models'
import { backendFetch } from './fetch'

/**
 * Update the `goLive` property of a post
 * @param doc
 * @param updatePayload
 */
export const updateDocumentGoLive = (
  doc: B2CUpdate,
  updatePayload: Pick<B2CUpdate, 'goLive'>
) => {
  doc.goLive = updatePayload.goLive
  Object.assign(doc, updatePayload)
}
