export * from './auth'

import { csrfCookieName } from '@/constants'
const { get: getCookie, remove: removeCookie } = require('js-cookie')

type FetchResponse<T> = {
  status: number
  data?: T & { csrfToken?: string }
  errors?: string[]
}

type Options = {
  /**
   * Should we retry the fetch if we aren't returned a successful status code?
   * @default false
   */
  allowRetry?: boolean
  /**
   * Use cached responses?
   * @default false
   */
  useCache?: boolean
}

const maxFetchRetries = 2
const cachedResponses: Record<string, FetchResponse<any> | null> = {}

/**
 * A csrf token sent by the server upon logging in
 */
let cachedCsrfToken = ''

/**
 * Make a request to our backend host.
 * By default sets `credentials: 'include'` and a header
 * of `'Content-Type': 'application/json'`
 * @param path
 * @param init
 * @returns
 */
export const backendFetch = <T = string[] | undefined>(
  path: string,
  init?: RequestInit,
  { allowRetry = false, useCache = false }: Options = {}
): Promise<FetchResponse<T>> => {
  if (useCache && cachedResponses[path]) {
    const { status, data } = cachedResponses[path]!
    return new Promise((resolve) =>
      resolve({
        status,
        data: data as T,
      })
    )
  }
  return executeFetch<T>(path, init, { allowRetry, useCache }).then((r) => {
    if (r.data && useCache) cachedResponses[path] = r
    return r
  })
}

type ExecuteFetchOptions = {
  /**
   * The status of the previous fetch that caused a retry
   */
  readonly failedStatus?: number
  /**
   * Recursively increments for each fetch attempt
   */
  readonly tries?: number
} & Options

const executeFetch = <T>(
  path: string,
  init: RequestInit = { headers: {} },
  {
    allowRetry = true,
    failedStatus = 200,
    tries = 1,
    useCache,
  }: ExecuteFetchOptions = {}
): Promise<FetchResponse<T>> => {
  if (tries > maxFetchRetries) {
    return new Promise((resolve) =>
      resolve({
        status: failedStatus,
      })
    )
  }
  const { headers, ...rest } = init
  return fetch(process.env.VUE_APP_BACKEND_HOST + path, {
    credentials: 'include',
    headers: {
      ...(cachedCsrfToken && { 'x-csrf-token': cachedCsrfToken }),
      ...headers,
    },
    ...rest,
  }).then(async (r) => {
    // The server sends a csrf token if we don't have one
    // and if we are authenticated
    const csrfToken = getCookie(csrfCookieName)
    if (csrfToken) {
      cachedCsrfToken = csrfToken
      // The token should only last for the session
      removeCookie(csrfCookieName)
    }
    const isError = r.status >= 300
    // Retry the fetch if it fails
    if (isError && allowRetry) {
      return executeFetch(path, init, {
        failedStatus: r.status,
        tries: tries + 1,
      })
    }

    try {
      const data = await r.json()
      const responseData = {
        status: r.status,
        data: isError ? null : data,
        errors: isError ? data : null,
      }
      cachedResponses[path] = useCache ? responseData : null
      return responseData
    } catch (error) {
      return {
        status: r.status,
        errors: isError ? ['An error occurred.'] : [],
      }
    }
  })
}
