import { afterFetchUserData } from '@/helpers/fetch'
import { user } from '@/store'
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

export const waitForWhoAmIResponse = async () => {
  if (user._id) return
  await new Promise<void>((r) => afterFetchUserData.push(() => r()))
}

/**
 * Wait for the user's initial /whoami request to complete before
 * navigating to this route.
 */
export const waitForWhoAmIResponseMiddleware = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (user._id) next()
  await new Promise<void>((r) => afterFetchUserData.push(() => r()))
  next()
}
