export * from './mini'
export * from './helpers'
import { reactive } from 'vue'
import { backendFetch } from '@/helpers/fetch'
import { indexedRoutes, routeNames } from '@/router'
import {
  B2CUpdate,
  B2BAdvice,
  EnergyIntensity,
  B2CChallenge,
} from '@/types/server/models'
import { Overview } from './types'
import { energyIntensities } from './mini'
import { getRouteData } from './helpers'
import { fetchB2CChallengeEditorData } from './helpers/b2cChallenges'

/**
 * The data for each content management route
 */
export const overviews = reactive<Record<keyof typeof indexedRoutes, Overview>>(
  {
    'b2c-updates': new Overview<B2CUpdate>(
      'B2C Updates',
      'Which project do you want to work on?',
      {
        name: routeNames.OVERVIEW,
        params: { overview: indexedRoutes['b2c-updates'] },
      },
      () =>
        getRouteData(
          '/api/data/overview/b2c-updates',
          routeNames['b2c-updates']
        ),
      async (projectId) => {
        const { data } = await backendFetch<B2CUpdate[]>(
          `/api/data/${indexedRoutes['b2c-updates']}/projectId/${projectId}`
        )
        return data!
      },
      async (docId) => {
        const { data } = await backendFetch<B2CUpdate>(
          `/api/data/${indexedRoutes['b2c-updates']}/_id/${docId}`
        )
        return data!
      }
    ),
    'b2c-challenges': new Overview<B2CChallenge>(
      'Move Challenges',
      'Which topic you want to work on?',
      {
        name: routeNames.OVERVIEW,
        params: { overview: indexedRoutes['b2c-challenges'] },
      },
      () =>
        getRouteData(
          `/api/data/overview/${indexedRoutes['b2c-challenges']}`,
          routeNames['b2c-challenges']
        ),
      async (category) => {
        const [{ data }] = await Promise.all([
          backendFetch<B2CChallenge[]>(
            `/api/data/${indexedRoutes['b2c-challenges']}/category/${category}`
          ),
          fetchB2CChallengeEditorData(),
        ])
        return data!
      },
      async (docId) => {
        const [{ data: routeData }] = await Promise.all([
          backendFetch<B2CChallenge>(
            `/api/data/${indexedRoutes['b2c-challenges']}/_id/${docId}`
          ),
          fetchB2CChallengeEditorData(),
        ])
        return routeData!
      }
    ),
    'business-advice': new Overview<B2BAdvice>(
      'B2B Advice',
      'Which topic do you want to work on?',
      {
        name: routeNames.OVERVIEW,
        params: { overview: indexedRoutes['business-advice'] },
      },
      () =>
        getRouteData(
          '/api/data/overview/business-advice',
          routeNames['business-advice']
        ),
      async (topic) => {
        const [{ data: routeData }, { data: energyIntensitiesData }] =
          await Promise.all([
            backendFetch<B2BAdvice[]>(
              `/api/data/${indexedRoutes['business-advice']}/topic/${topic}`
            ),
            backendFetch<EnergyIntensity[]>(
              '/api/data/energy-intensities',
              {},
              { useCache: true }
            ),
          ])
        energyIntensities.value = energyIntensitiesData!
        return routeData!
      },
      async (docId) => {
        const { data } = await backendFetch<B2BAdvice>(
          `/api/data/${indexedRoutes['business-advice']}/_id/${docId}`
        )
        return data!
      }
    ),
  }
)
