
import { defineComponent, ref, watch } from 'vue'
import { RouteLocationRaw, useRouter } from 'vue-router'

import { routeNames } from '@/router'
import { inviteUserPopupState, overviews } from '@/store'
import { backendFetch } from '@/helpers/fetch'

import Button from '@/components/atoms/Button'
import TheNavItem, { NavItem } from '@/components/atoms/TheNavItem'

export default defineComponent({
  components: {
    Button,
    TheNavItem,
  },
  setup() {
    const router = useRouter()
    const showMobileNav = ref(false)

    /**
     * Is the current route a child of `parentRouteName`? If so,
     * append the 'router-link-active' class to the element.
     * E.g. if we're on /dashboard/products we highlight the corresponding element in the left side navigation.
     * If we go to /dashboard/products/subscription then we want to keep it highlighted.
     * It is considered a child route becuase it includes the parent string at the beginning .
     */
    const isActiveChildRouteClass = (route: RouteLocationRaw) =>
      new RegExp(`^${router.resolve(route).path}`).test(
        router.currentRoute.value.path
      )
        ? 'router-link-active'
        : ''

    watch(
      () => router.currentRoute.value,
      () => {
        showMobileNav.value = false
      }
    )

    const upperItems: NavItem[] = [
      {
        title: 'B2C Updates',
        route: overviews['b2c-updates'].anchorTo as RouteLocationRaw,
        getClass: () =>
          isActiveChildRouteClass(
            overviews['b2c-updates'].anchorTo as RouteLocationRaw
          ),
      },
      {
        title: 'B2C Challenges',
        route: overviews['b2c-challenges'].anchorTo as RouteLocationRaw,
        getClass: () =>
          isActiveChildRouteClass(
            overviews['b2c-challenges'].anchorTo as RouteLocationRaw
          ),
      },
      {
        title: 'B2B Advice',
        route: overviews['business-advice'].anchorTo as RouteLocationRaw,
        getClass: () =>
          isActiveChildRouteClass(
            overviews['business-advice'].anchorTo as RouteLocationRaw
          ),
      },
    ]

    const ownerViewUpperItems: NavItem[] = [
      {
        title: 'Compensations',
        route: { name: routeNames.COMPENSATIONS },
      },
      {
        title: 'Companies',
        route: { name: routeNames.COMPANIES },
      },
    ]

    const logout = async () => {
      await backendFetch('/api/auth/logout', { method: 'POST' })
      router.push({ name: routeNames.LOGIN })
    }

    return {
      upperItems,
      ownerViewUpperItems,
      showMobileNav,
      logout,
      inviteUserPopupState,
    }
  },
})
