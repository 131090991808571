import { overviews } from '@/store'
import { RouteRecordRaw } from 'vue-router'
import { indexedRoutes, routeNames } from '.'
import { waitForWhoAmIResponse } from './middleware'

export const checkToFetchItems = async (
  overviewKey: keyof typeof indexedRoutes,
  groupId: string,
  refresh?: boolean
) => {
  await Promise.all([
    refresh || !overviews[overviewKey].GroupedDocuments[groupId]?.length
      ? overviews[overviewKey].FetchGroupedDocuments(groupId)
      : null,
    !overviews[overviewKey].OverviewItems.length
      ? overviews[overviewKey].FetchOverviewItems()
      : null,
  ])
}

export const getGroupViewRouteOptions = (
  overviewKey: keyof typeof indexedRoutes
): Pick<RouteRecordRaw, 'meta' | 'beforeEnter'> => ({
  meta: {
    layout: 'Dashboard',
    redirectToIfNoAuth: {
      name: routeNames.LOGIN,
    },
  },
  beforeEnter: async (to, from, next) => {
    const shouldRefresh =
      !!to.query.refresh || from.name === routeNames.OVERVIEW

    await checkToFetchItems(
      overviewKey,
      to.params.groupId as string,
      shouldRefresh
    )

    return next()
  },
})

export const getEditorRouteOptions = (
  overviewKey: keyof typeof indexedRoutes
): Pick<RouteRecordRaw, 'meta' | 'beforeEnter'> => ({
  meta: {
    layout: 'Dashboard',
    redirectToIfNoAuth: {
      name: routeNames.LOGIN,
    },
  },
  beforeEnter: async (to, from, next) => {
    await waitForWhoAmIResponse()
    const { groupId, docId, refresh } = to.params as {
      groupId: string
      refresh?: string
      docId?: string
    }
    await Promise.all([
      checkToFetchItems(overviewKey, groupId, !!refresh),
      docId ? overviews[overviewKey].FetchSingleDocument(docId) : null,
    ])
    return next()
  },
})
