import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, mergeProps as _mergeProps, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d583510c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative w-full" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "text-grey-fp-3"
}
const _hoisted_4 = { class: "relative flex items-center" }
const _hoisted_5 = ["stroke"]
const _hoisted_6 = ["stroke"]
const _hoisted_7 = ["stroke"]
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$attrs.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.$attrs.name,
          class: "top-label"
        }, [
          _createTextVNode(_toDisplayString(_ctx.$attrs.label) + " ", 1),
          (_ctx.$attrs.optional)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "(Optional)"))
            : _createCommentVNode("", true)
        ], 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (typeof _ctx.downArrowDir === 'string' || _ctx.showDeleteButton)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "side-content w-8 h-8 flex items-center justify-center absolute right-3 cursor-pointer",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.input?.focus()))
          }, [
            (typeof _ctx.downArrowDir === 'string')
              ? (_openBlock(), _createElementBlock("svg", {
                  key: 0,
                  width: "16",
                  height: "9",
                  viewBox: "0 0 16 9",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                  class: _normalizeClass(_ctx.downArrowDir === 'up' ? 'transform rotate-180' : '')
                }, [
                  _createElementVNode("path", {
                    d: "M15 1L8 8L1 1",
                    stroke: _ctx.darkMode ? 'white' : 'black',
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }, null, 8, _hoisted_5)
                ], 2))
              : (_ctx.$attrs.value)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput(''))),
                    "aria-label": "delete part"
                  }, [
                    (_openBlock(), _createElementBlock("svg", {
                      width: "18",
                      height: "18",
                      viewBox: "0 0 18 18",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                      class: _normalizeClass(["w-4 h-4", [
              _ctx.downArrowDir === 'up' ? 'transform rotate-180' : '',
              'cursor-pointer',
            ]])
                    }, [
                      _createElementVNode("path", {
                        d: "M17 1L1 17",
                        stroke: _ctx.darkMode ? 'white' : 'black',
                        "stroke-width": "2",
                        "stroke-linecap": "round"
                      }, null, 8, _hoisted_6),
                      _createElementVNode("path", {
                        d: "M0.999999 0.999999L17 17",
                        stroke: _ctx.darkMode ? 'white' : 'black',
                        "stroke-width": "2",
                        "stroke-linecap": "round"
                      }, null, 8, _hoisted_7)
                    ], 2))
                  ]))
                : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("input", _mergeProps({ ref: "input" }, _ctx.$attrs, {
        onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
        onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
        class: ["rounded-xl input w-full shadow-none focus:outline-none border border-grey-fp-3", [
          _ctx.darkMode ? 'bg-black' : 'bg-white text-black light',
          _ctx.showGreenBorderOnFocus ? 'focus:border-green-fp-2' : '',
          _ctx.textIfEmptyAndBlurred && _ctx.showTextIfEmptyAndBlurred
            ? 'border-warn-strong'
            : '',
        ]],
        disabled: _ctx.disabled
      }), null, 16, _hoisted_8),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    (_ctx.errorText || (_ctx.textIfEmptyAndBlurred && _ctx.showTextIfEmptyAndBlurred))
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: _normalizeClass(["error-text text-warn-strong mt-2", 
        _ctx.textIfEmptyAndBlurredAbsolutePosition
          ? 'absolute -bottom-7 left-4'
          : 'pl-4'
      ]),
          innerHTML: 
        _ctx.errorText
          ? _ctx.errorText
          : _ctx.showTextIfEmptyAndBlurred
          ? _ctx.textIfEmptyAndBlurred
          : ''
      
        }, null, 10, _hoisted_9))
      : _createCommentVNode("", true)
  ]))
}