
import { defineComponent, onMounted, ref, nextTick } from 'vue'

import InputMixin from '@/mixins/InputMixin'

export default defineComponent({
  inheritAttrs: false,
  emits: [...InputMixin.emits],
  props: {
    ...InputMixin.props,
  },
  setup(props, { emit }) {
    const input = ref<HTMLInputElement | null>(null)
    const showTextIfEmptyAndBlurred = ref(false)

    onMounted(() => {
      if (props.focusOnLoad) input.value?.focus()
    })

    const onBlur = async () => {
      await nextTick()
      showTextIfEmptyAndBlurred.value = !input.value?.value
    }

    const onInput = (
      value: (InputEvent & { target: HTMLInputElement }) | string
    ) => {
      showTextIfEmptyAndBlurred.value = false

      if (typeof value === 'string') {
        input.value!.value = value
        emit('input-value', value)
        return
      }

      emit('input-value', value.target.value)
    }

    return {
      input,
      onBlur,
      onInput,
      showTextIfEmptyAndBlurred,
    }
  },
})
