import { RouteRecordRaw } from 'vue-router'
import { indexedRoutes, routeNames } from '..'
import dashboardRoutes from './dashboard'

const LoginPage = () => import('@/views/Login')
const RegisterPage = () => import('@/views/Register')
const ErrorPage = () => import('@/views/Error')

const routes: RouteRecordRaw[] = [
  ...dashboardRoutes,
  {
    path: '/',
    name: routeNames.LOGIN,
    component: LoginPage,
    meta: {
      layout: 'Default',
      showHeader: true,
      redirectToIfAuth: {
        name: routeNames.OVERVIEW,
        params: { overview: indexedRoutes['business-advice'] },
      },
    },
  },
  {
    path: '/register/:token',
    name: routeNames.REGISTER,
    component: RegisterPage,
    meta: {
      layout: 'Default',
      showHeader: true,
      redirectToIfAuth: {
        name: routeNames.OVERVIEW,
        params: { overview: indexedRoutes['business-advice'] },
      },
    },
  },
  {
    path: '/error',
    name: routeNames.ERROR,
    component: ErrorPage,
  },
  {
    path: '/:catchAll(.*)',
    component: ErrorPage,
  },
]

export default routes
