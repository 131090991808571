export const convertSecondsToDays = (value?: number) => {
  if (typeof value !== 'number') return
  return value / 86400
}

/**
 * Returns a localized human readable date.
 * @param timeMs Time in milliseconds
 * @returns
 */
export const getReadableDate = (timeMs: number) => {
  const date = new Date(timeMs)
  return date.toLocaleString('default', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })
}

/**
 * Escapes all special characters in a string that should
 * be used as a regular expression.
 */
export const getEscapedTextForRegExp = (text: string) => {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}
