export default {
  emits: ['input-value'] as const,
  props: {
    downArrowDir: {
      type: String,
      required: false,
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: '',
    },
    focusOnLoad: {
      type: Boolean,
      default: false,
    },
    showGreenBorderOnFocus: {
      type: Boolean,
      default: true,
    },
    darkMode: {
      type: Boolean,
      default: true,
    },
    textIfEmptyAndBlurred: {
      type: String,
      required: false,
    },
    textIfEmptyAndBlurredAbsolutePosition: {
      type: Boolean,
      default: true,
    },
  },
}
