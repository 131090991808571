export const ingredientUnits: string[] = [
  'g',
  'kg',
  'l',
  'ml',
  'oz',
  'lb',
  'tbsp',
  'piece(s)',
  'cup',
  'scoop',
  'bag',
  'bar',
  'clove(s)',
  'dash(es)',
  'head(s)',
  'leaf (leaves)',
  'package(s)',
  'pinch',
  'sprig',
  'stalk(s)',
  'tea bag(s)',
]
