
import { defineComponent, watchEffect } from 'vue'
import { useRouter } from 'vue-router'

import { routeNames } from '@/router'
import { fetchUserData } from './helpers/fetch'
import {
  windowState,
  confirmPopupState,
  inviteUserPopupState,
  user,
} from './store'

import Header from '@/components/organisms/Header'
import Footer from '@/components/organisms/Footer'
import Popup from '@/components/molecules/Popup'
import TheInviteUsers from '@/components/organisms/TheInviteUsers'
import LayoutConstructor from '@/layouts/LayoutConstructor'

export default defineComponent({
  components: {
    Header,
    Footer,
    Popup,
    TheInviteUsers,
    LayoutConstructor,
  },
  setup() {
    const router = useRouter()

    const main = async () => {
      try {
        await Promise.all([fetchUserData(), router.isReady()])

        if (user._id) {
          const { redirectToIfAuth } = router.currentRoute.value.meta
          if (redirectToIfAuth) router.push(redirectToIfAuth)
        } else {
          const { redirectToIfNoAuth } = router.currentRoute.value.meta
          if (redirectToIfNoAuth) router.push(redirectToIfNoAuth)
        }
      } catch (error) {
        console.error(error)
        router.push({
          name: routeNames.ERROR,
          params: {
            message: 'Servers are down, try again later',
            status: 500,
            hideBackLink: 1,
          },
        })
      }
    }

    main()

    watchEffect(
      () => {
        document.body.style.overflow = windowState.allowScroll ? '' : 'hidden'
      },
      {
        flush: 'post',
      }
    )

    return { confirmPopupState, inviteUserPopupState }
  },
})
