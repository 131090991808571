import { ref, reactive, computed } from 'vue'
import { userRole } from '@/constants'
import { EnergyIntensity } from '@/types/server/models'
import { ConfirmPopupState } from './types'
import { GetB2CChallengesEditorData } from '@/types/server/routes/data'

export const windowState = reactive({
  allowScroll: true,
})

export const confirmPopupState = reactive<ConfirmPopupState>({
  show: false,
  title: '',
  message: '',
})

export const inviteUserPopupState = reactive({
  show: false,
})

export const user = reactive({
  _id: '',
  email: '',
  role: '' as keyof typeof userRole,
  /**
   * Has the user called /whoami yet?
   */
  hasLoaded: true,
})

export const energyIntensities = ref<EnergyIntensity[]>([])

export const energyIntensityCategories = computed(() =>
  energyIntensities.value.map((e) => e.category)
)

export const b2cChallengesEditorRouteData = ref<GetB2CChallengesEditorData>()
